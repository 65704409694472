<template>
  <div>
    <div class="location-list-filters">
      <div class="location-list-filters__section">
        <div class="location-list-filters__section__label">
          {{ $t('gmbQa.list.filters.status.label') }}
        </div>
        <div class="location-list-filters__section__radios">
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="status-all"
            value="ENABLED|DISABLED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('gmbQa.list.filters.status.all') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="status-enabled"
            value="ENABLED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('gmbQa.list.filters.status.enabled') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="status-disabled"
            value="DISABLED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('gmbQa.list.filters.status.disabled') }}
          </ui-radio>
        </div>
      </div>
      <div class="location-list-filters__section">
        <div class="location-list-filters__section__label">
          {{ $t('gmbQa.list.filters.owner.label') }}
        </div>
        <div class="location-list-filters__section__radios">
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="owner-all"
            value="ENABLED|DISABLED"
            name="owner"
            :reverse="true"
            v-model="filters.owner"
          >
            {{ $t('gmbQa.list.filters.owner.all') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="owner-enabled"
            value="ENABLED"
            name="owner"
            :reverse="true"
            v-model="filters.owner"
          >
            {{ $t('gmbQa.list.filters.owner.enabled') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="owner-disabled"
            value="DISABLED"
            name="owner"
            :reverse="true"
            v-model="filters.owner"
          >
            {{ $t('gmbQa.list.filters.owner.disabled') }}
          </ui-radio>
        </div>
      </div>
      <div class="location-list-filters__section">
        <div class="location-list-filters__section__label">
          {{ $t('gmbQa.list.filters.withResponse.label') }}
        </div>
        <div class="location-list-filters__section__radios">
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="withResponse-all"
            value="ENABLED|DISABLED"
            name="withResponse"
            :reverse="true"
            v-model="filters.withResponse"
          >
            {{ $t('gmbQa.list.filters.withResponse.all') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="withResponse-enabled"
            value="ENABLED"
            name="withResponse"
            :reverse="true"
            v-model="filters.withResponse"
          >
            {{ $t('gmbQa.list.filters.withResponse.enabled') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="withResponse-disabled"
            value="DISABLED"
            name="withResponse"
            :reverse="true"
            v-model="filters.withResponse"
          >
            {{ $t('gmbQa.list.filters.withResponse.disabled') }}
          </ui-radio>
        </div>
      </div>
      <div class="location-list-filters__section__buttons">
        <div class="location-list-filters__section__buttons__reset">
          <ui-button button="secondary" icon="reset" :label="$t('locations.list.filters.reset')" @click="reset" />
        </div>
        <div class="location-list-filters__section__buttons__close">
          <ui-button button="primary" v-close-popover icon="close" :label="$t('locations.list.filters.close')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiRadio from '@/components/UI/Radio.vue'
import UiButton from '@/components/UI/Button.vue'

const getStringifiedStatus = value => {
  switch (value) {
    case 'ENABLED':
      return 'status:1'
    case 'DISABLED':
      return 'status!1'
    case 'ENABLED|DISABLED':
    default:
      return ''
  }
}

const getStringifiedOwner = value => {
  switch (value) {
    case 'ENABLED':
      return 'owner:1'
    case 'DISABLED':
      return 'owner!1'
    case 'ENABLED|DISABLED':
    default:
      return ''
  }
}

const getStringifiedWithResponse = value => {
  switch (value) {
    case 'ENABLED':
      return 'withResponse:1'
    case 'DISABLED':
      return 'withResponse!1'
    case 'ENABLED|DISABLED':
    default:
      return ''
  }
}

const defaultFilters = {
  status: 'ENABLED|DISABLED',
  owner: 'ENABLED|DISABLED',
  withResponse: 'ENABLED|DISABLED',
}

export default {
  name: 'LocationListFilter',
  components: {
    UiRadio,
    UiButton,
  },
  props: {
    frontofficesFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      filters: {
        ...defaultFilters,
      },
    }
  },
  computed: {
    stringifiedSearch() {
      return [
        getStringifiedStatus(this.filters.status),
        getStringifiedOwner(this.filters.owner),
        getStringifiedWithResponse(this.filters.withResponse),
      ]
        .filter(filter => filter && filter.length > 0)
        .join(',')
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: 'onUpdateFilters',
    },
  },
  methods: {
    onUpdateFilters() {
      this.$emit('onUpdateFilters', this.stringifiedSearch)
    },
    reset() {
      this.filters = { ...defaultFilters }
    },
  },
}
</script>

<style lang="scss" scoped>
.location-list-filters {
  margin: 0 auto;

  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__label {
      margin-bottom: 0.5rem;
    }

    &__label,
    &__checkbox {
      font-weight: 600;
    }

    &__radios {
      display: flex;
      justify-content: space-between;
      &__radio {
        margin: 0 0.5rem;

        &:first-child {
          margin: 0;
          margin-right: 0.5rem;
        }
        &:last-child {
          margin: 0;
          margin-left: 0.5rem;
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
    }
  }
}
</style>
